import React from 'react';
import { useTranslation } from "react-i18next";
import { Col, Row, Container } from 'react-bootstrap';
import { FaRobot, FaSearch, FaFileAlt } from 'react-icons/fa';

import "styles/theme.css";
import { NewReportPanel } from './new_report';
import Page from 'components/page';

const FeatureCard = ({ title, description, icon: Icon }) => (
    <Col md={4} className="mb-4">
        <div className="card h-100 shadow-sm transition-all"
            style={{
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                cursor: 'pointer'
            }}
            onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'translateY(-5px)';
                e.currentTarget.style.boxShadow = '0 .5rem 1rem rgba(0,0,0,.15)';
            }}
            onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow = '0 .125rem .25rem rgba(0,0,0,.075)';
            }}
        >
            <div className="card-body text-center">
                <div className="feature-icon mb-3">
                    <Icon className="fs-2 custom-feature-icon" />
                </div>
                <h5 className="card-title">{title}</h5>
                <p className="card-text text-muted">{description}</p>
            </div>
        </div>
    </Col>
);

const features = [
    {
        title: "AI-Powered Analysis",
        description: "Advanced artificial intelligence algorithms to detect grammar, spelling, and SEO issues with high accuracy",
        icon: FaRobot
    },
    {
        title: "Full Website Scan",
        description: "Comprehensive scanning of all your website pages for content errors and SEO optimization opportunities",
        icon: FaSearch
    },
    {
        title: "Detailed Reports",
        description: "Get detailed reports with specific suggestions to improve your content quality and search engine rankings",
        icon: FaFileAlt
    }
];

const Home = () => {
    const [t] = useTranslation('common');

    return (
        <Page title={t('Website audit service')} description={t('AI-powered website audit service to check your website\'s text for grammatical errors and typos')}>
            <section className="section-header pt-3 pb-lg-3 overflow-hidden" id="home">
                <Container>
                <Row>
                    <Col xs={12} className="text-center">
                    <h1 className="fw-normal">
                        AI-Powered Grammar & SEO Audit for Websites
                    </h1>
                    <p className="text-muted fw-light mb-5 h5">Ensure your website is error-free with AI-driven grammar, spelling, and SEO checks</p>
                    </Col>
                </Row>
                </Container>
            </section>
            <section className="py-1">
                <Container>
                    <Row>
                        {features.map((feature, index) => (
                            <FeatureCard key={index} {...feature} />
                        ))}
                    </Row>
                </Container>
            </section>
            <NewReportPanel/>
        </Page>
    );
}

export default Home;