import React, { useState, useEffect } from "react";

import { Container, Table, Card, ProgressBar, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';


import { Paths } from "paths";
import { useTranslation } from "react-i18next";

import getBackend from "backend/backend";
import { capitalizeFirstLetter } from "components/misc";
import ShareButton from "components/share_button";
import Page from 'components/page';
import SidebarPage from 'components/sidebar_page';

const IssuesTable = (props) => {
    const sortIssuesBySeverity = (issues) => {
        const severityOrder = ['critical', 'high', 'medium', 'low'];
        return issues.sort((a, b) => severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity));
    };

    const issues = sortIssuesBySeverity(props.issues);

    const severityToStatus = (severity) => {
        switch (severity) {
        case "critical":
            return "danger";
        case "high":
            return "warning";
        case "medium":
            return "info";
        case "low":
            return "muted";
        default:
            return "primary";
        }
    }

    const relativeUrl = (url, root_url) => {
        if (url.startsWith(root_url)) {
            let result = url.substring(root_url.length);
            if (result === "") {
                result = "/";
            }
            return result;
        }
        return url;
    }

    const IssuesTableRow = (props) => {
        const { issue, root_url } = props;
        const status = severityToStatus(issue.severity);

        const shortenString = (str, maxLength) => {
            if (str.length <= maxLength) {
                return str;
            }
            return str.substring(0, maxLength - 3) + "...";
        }

        const getIssueDetails = (issue) => {
            return shortenString(issue.description, 200);
        }

        return (
            <tr className="border-bottom">  
                <td>
                <span className={`fw-normal text-${status}`}>
                    {capitalizeFirstLetter(issue.severity)}
                </span>
                </td>

                <td><span className="fw-normal"><a className="page-link" href={issue.url}>{relativeUrl(issue.url, root_url)}</a></span></td>

                <td title={issue.description}>
                <span className="fw-normal">{getIssueDetails(issue)}</span>&nbsp;
                </td>

            </tr>
        );
    };

    return (
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Severity</th>
                <th className="border-bottom">Page</th>
                <th className="border-bottom">Description</th>
              </tr>
            </thead>
            <tbody className="border-0">
              {issues.map((u, index) => <IssuesTableRow key={`issue-${index}`} issue={u} root_url={props.root_url}/>)}
            </tbody>
          </Table>
    );
  };


const ReportProgressBar = (props) => {
    return (
        <ProgressBar animated variant="primary" now={props.progress} label={`${props.progress}%`} className="report-progress-bar"/>
    );
}

const ReportCard = (props) => {
    const report = props.report;
    const critical_errors_count = report.stats.issues_count["critical"];
    const high_errors_count = report.stats.issues_count["high"];
    const medium_errors_count = report.stats.issues_count["medium"];
    const low_errors_count = report.stats.issues_count["low"];
    const pages_count = report.stats.pages_count;

    const kindMap = {
        "grammar_audit": "Grammar Audit",
        "seo_audit": "SEO Audit"
    }

    const handleDownload = () => {
        // Create CSV content
        const headers = ['Severity', 'URL', 'Description'];
        const rows = report.issues.map(issue => [
            issue.severity,
            issue.url,
            issue.description
        ]);

        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.map(cell => `"${cell.replace(/"/g, '""')}"`).join(','))
        ].join('\n');

        // Create and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `sitechecker-analysis-${report.url.replace(/[^a-z0-9]/gi, '-')}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Container className="min-vh-100 my-5">
            <section className="justify-content-center">
                <Card>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <Card.Title><h1>Site analysis <a className="report-link" href={report.url}>{report.url}</a></h1></Card.Title>
                        <div>
                            <Button variant="light" className="custom-button me-2" onClick={handleDownload}>
                                Download CSV <i className="bi bi-download"></i>
                            </Button>
                            <ShareButton reportLink={window.location.href}/>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <h6>
                            Kind: {kindMap[report.kind]}
                        </h6>
                        <h6>
                            Pages Scanned: {pages_count}
                        </h6>
                        {report.kind === "grammar_audit" &&
                            <>
                                <h6>
                                    AI Model: {report.ai_model}
                                </h6>
                                <h6>
                                    Language: {report.lang}
                                </h6>
                            </>
                        }
                        <h6>
                            Issues Found: <span className="text-danger"><strong>Critical:</strong> {critical_errors_count}</span> | <span className="text-warning"><strong>High:</strong> {high_errors_count}</span> | <span className="text-info"><strong>Medium:</strong> {medium_errors_count}</span> | <span className="text-muted"><strong>Low:</strong> {low_errors_count}</span>
                        </h6>
                        {report.progress < 100 && report.report_error === "" &&
                            <>
                            {report.status !== "" &&
                                <h5>Status: {report.status}. Please wait and do not close this page...</h5>
                            }
                            <ReportProgressBar progress={report.progress}/>
                            </>
                        }
                        {report.report_error !== "" &&
                            <Alert variant="danger" className="text-center">
                                Error: {report.report_error}
                            </Alert>
                        }
                        <IssuesTable issues={report.issues} root_url={report.url}/>
                        {(report.hidden && report.payment_link !== "") &&
                            <Alert variant="info" className="text-center">
                                <h5>Other issues are hidden. Complete the payment to view them.</h5>
                                <Button variant="light" className="custom-button mt-3" as={Link} to={report.payment_link}>
                                    Unlock Issues <span className="icon icon-xs ms-2" />
                                </Button>
                            </Alert>
                        }
                    </Card.Body>
                </Card>
            </section>
        </Container>
    )
}

const Report = () => {
    const {report_id} = useParams();
    const [t] = useTranslation('common');
    const [profile, setProfile] = useState(null);
    const [report, setReport] = useState({progress: 1, report_error: "", status: "loading",
        issues: [], hidden: false, payment_link: "",
        stats: {pages_count: 0, issues_count: {"low": 0, "medium": 0, "high": 0, "critical": 0}},
        ai_model: "", lang: ""});

    useEffect(() => {
      let canceled = false;
  
        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            }
        };

        const getReport = async () => {
            const result = await getBackend().getReport(report_id);
            if (canceled)
                return {progress: 0, error: ""};

            if (result.error == null) {
                setReport(result.response);

                return {progress: result.response.progress, error: result.response.report_error};
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getProfile();

        const timer = setInterval(async () => {
            if (!canceled) {
                const {progress, error} = await getReport();
                if (progress >= 100 || error !== "") {
                    clearInterval(timer);
                }
            }
        }, 1000); // Update every 1 second

        return async () => {
            clearInterval(timer);
            canceled = true;
        }
  }, [report_id]);

  return (
    <>
        {profile ?
            <SidebarPage title={t('Report')} description={t('Report')}>
                <ReportCard
                    report={report}
                />
            </SidebarPage>
            :
            <Page title={t('Report')} description={t('Report')}>
                <ReportCard
                    report={report}
                />
            </Page>
        }
    </>
  );
};

export default Report;