import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Alert, Button, Container, Form } from 'react-bootstrap';

import "styles/theme.css";
import getBackend from 'backend/backend';
import { Paths } from 'paths';
import { showConfirmation } from "components/misc";
import SidebarPage from 'components/sidebar_page';


export const ai_models = ["gpt-4o-mini", "gpt-4o", "o1-mini"];
export const langs = ["any","english", "british_english", "american_english", "spanish", "french", "german", "italian", "portuguese", "chinese", "hindi"];

export const NewReportPanel = () => {
    const [urlValue, setUrlValue] = useState("");
    const [selectedModel, setSelectedModel] = useState(ai_models[0]);
    const [selectedLang, setSelectedLang] = useState(langs[0]);
    const [selectedKind, setSelectedKind] = useState("grammar_audit");
    const [urlsLimit, setUrlsLimit] = useState(30);
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState("");
    const [paid, setPaid] = useState(false);

    useEffect(() => {
        let canceled = false;

        const getSettings = async () => {
            const result = await getBackend().getSettings();
            if (canceled)
                return;

            if (result.error == null) {
                setSelectedLang(result.response.settings.lang);
                setSelectedModel(result.response.settings.ai_model);
                setUrlsLimit(result.response.settings.urls_limit);
                setUrlValue(result.response.settings.url);
            }
        };

        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
                return;

            if (result.error == null) {
                setProfile(result.response);
            }
        };

        getProfile();
        getSettings();

        return async () => {
            canceled = true;
        }
    }, []);

    const changeUrlValue = (e) => {
        setUrlValue(e.target.value);
    };

    const normalizeUrl = (value) => {
        try {
            let newValue = value;

            if (!value.startsWith("http://") &&
                !value.startsWith("https://")) {
                newValue = "https://" + value;
            }

            const url = new URL(newValue);
            if (url.protocol === 'http:' || url.protocol === 'https:') {
                return url.origin + url.pathname;
            }
        } catch (err) {
            return "";
        }
    }

    const startReport = async () => {
        const normUrl = normalizeUrl(urlValue);
        if (normUrl === "") {
            setError("Invalid URL: " + urlValue + ". Please enter a valid website URL that starts with http:// or https://.");
            return;
        }

        const params = {
            url: normUrl,
            ai_model: selectedModel,
            lang: selectedLang,
            urls_limit: urlsLimit,
            paid: paid,
            kind: selectedKind,
        };
        const result = await getBackend().createReport(params);
        if (result.error == null) {
            window.location.href = Paths.Report.path + "/" + result.response.id;
        } else {
            setError(result.error);
            if (profile === null && result.error.includes("unauthorized")) {
                let result = await showConfirmation("You have reached your free usage limit. To continue using our services, please consider upgrading to a premium report or upgrading your account.", "Would you like to view our paid options?");
                if (result.isConfirmed) {
                    window.location.href = Paths.Pricing.path;
                }
            }
        }
    }

    return (
        <section className="section section-md bg-soft pt-lg-5 vh-100 d-flex" id="features">
            <Container>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Site URL</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your site's URL to check for grammar or SEO issues"
                            value={urlValue}
                            onChange={changeUrlValue}
                        />
                        <Form.Label>Audit type</Form.Label>
                        <Form.Select value={selectedKind} onChange={(e) => setSelectedKind(e.target.value)}>
                            <option value="grammar_audit">Grammar Audit</option>
                            <option value="seo_audit">SEO Audit</option>
                        </Form.Select>
                        {!profile &&
                            <Form.Check
                                type="checkbox"
                                id="paid-report-checkbox"
                                label="Upgrade to a Premium Report"
                                checked={paid}
                                onChange={(e) => setPaid(e.target.checked)}
                                className="mt-2"
                            />
                        }
                    </Form.Group>
                    {(profile || paid) &&
                        <Form.Group className="mb-3">
                        {selectedKind === "grammar_audit" &&
                            <>
                                <Form.Label>AI model</Form.Label>
                                <Form.Select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
                                    {ai_models.map((model, index) => (
                                        <option key={index} value={model}>{model}</option>
                                    ))}
                                </Form.Select>
                                <Form.Label>Language</Form.Label>
                                <Form.Select value={selectedLang} onChange={(e) => setSelectedLang(e.target.value)}>
                                    {langs.map((lang, index) => (
                                        <option key={index} value={lang}>{lang}</option>
                                    ))}
                                </Form.Select>
                            </>
                        }
                        <Form.Label>Page limit</Form.Label>
                        <Form.Control
                                    type="number"
                                    placeholder="Enter pages limit"
                                    value={urlsLimit}
                                    onChange={(e) => setUrlsLimit(Number(e.target.value))}
                        />
                        </Form.Group>
                    }
                    <Button className="custom-button" variant="light" onClick={startReport}>
                        Start Analysis
                    </Button>
                </Form>
            </Container>
        </section>
    )
}

const NewReport = () => {
    const [t] = useTranslation('common');

    return (
        <SidebarPage title={t('New Report')} description={t('New Report')}>
            <Container className="my-5">
                <h2 className="mb-4">{t('New Report')}</h2>
                <NewReportPanel/>
            </Container>
        </SidebarPage>
    );
}

export default NewReport;
